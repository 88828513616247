const Project = [
  {
    id: 5,
    projName: "NITI:Nepal IT Incubator",
    projImg: "/Images/projImages/Niti.webp",

    projInfo:
      "NITI is an Nepal IT Incubator under NCIT college that dedicated is to promoting entrepreneurship within NCIT by empowering students, alumni, faculty, and staff to turn their ideas into reality. Minimize the gap between the academic and business world by providing the necessary resources and support to help them succeed.",

    technology: "",
    projLink: "https://niti.ncit.edu.np/",
  },
  {
    id: 5,
    projName: "FlowSync:Traffic Light Optimization",
    projImg: "/Images/projImages/Flowsync.webp",

    projInfo:
      "FlowSync is a system that manipulates the traffic light with intensity of vehicle in the intersection, it allocates the time for the particular path dynamically according to the density in the intersection.This is the final year project working collaboratively with team members: Atit Kunwar, Utsab Maharajan, sagar Nemkul,Manjit Magar",

    technology: "Python-Django,React-js,Esp32,computer vision",
    projLink: "https://github.com/kunwaratit/FlowSync-TrafficLightOptimization",
  },
  {
    id: 2,
    projName: "Steganographic Secure File Storage System",
    projImg: "/Images/projImages/cryptography.webp",
    projInfo:
      "Steganographic secure file storage system is an web app that allows the user to secure the file using AES encryption and store those encrypted data into the image using steganography. ",
    technology: "HTML, CSS, JS, Python-Django, React-js",
    projLink: "https://github.com/kunwaratit/Image_steganography_LSB_Algorithm",
  },
  {
    id: 1,
    projName: "Blood Management System",
    projImg: "/Images/projImages/blood bm.jpeg",
    projInfo:
      "Blood management System is an web platform to locate the interested donor, and the requests form the patients.Html,css,javascript and php is used",
    technology: "HTML, CSS, JS, PHP",
    projLink: "",
  },

  {
    id: 3,
    projName: "Tank Game",
    projImg: "",
    projInfo:
      "It is a Tank game that can attack the enemies around the range of the it.",
    technology: "Unity",
    projLink: "https://github.com/kunwaratit/ShooterTank",
  },
];
export default Project;
