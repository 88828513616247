const Skilled = [
  {
    id: 1,
    skill: "python",
    logoskill: "/Images/python.svg", //value for percentage
    path: "https://www.python.org/",
  },
  {
    id: 16,
    skill: "django",
    logoskill: "/Images/django.svg",
    path: "https://django.com/",
  },

  {
    id: 9,
    skill: "C",
    logoskill: "/Images/C.png",
    path: "https://devdocs.io/c/",
  },
  {
    id: 10,
    skill: "C++",
    logoskill: "/Images/C++.png",
    path: "https://devdocs.io/cpp/",
  },
  {
    id: 11,
    skill: "HTML",
    logoskill: "/Images/html.png",
    path: "https://developer.mozilla.org/en-US/docs/Web/HTML",
  },
  {
    id: 12,
    skill: "Css",
    logoskill: "/Images/css.png",
    path: "https://www.w3.org/Style/CSS/",
  },
  {
    id: 14,
    skill: "js",
    logoskill: "/Images/js.png",
    path: "https://developer.mozilla.org/en-US/docs/Web/HTML",
  },
  {
    id: 13,
    skill: "php",
    logoskill: "/Images/php.png",
    path: "https://www.php.net/",
  },
  {
    id: 14,
    skill: "react",
    logoskill: "/Images/react.svg",
    path: "https://react.dev/",
  },
  {
    id: 14,
    skill: "react",
    logoskill: "/Images/nextjs.svg",
    path: "https://nextjs.org/",
  },
  {
    id: 8,
    skill: "C",
    logoskill: "/Images/mysql.png",
    path: "https://mysql.com/",
  },
  {
    id: 8,
    skill: "C",
    logoskill: "/Images/oracle.svg",
    path: "https://oracle.com/",
  },
  {
    id: 15,
    skill: "unity",
    logoskill: "/Images/mongodb.svg",
    path: "https://mongodb.com/",
  },
  {
    id: 15,
    skill: "unity",
    logoskill: "/Images/unity.jpeg",
    path: "https://unity.com/",
  },
  {
    id: 8,
    skill: "C",
    logoskill: "/Images/vscode.png",
    path: "https://vscode.com/",
  },
  {
    id: 8,
    skill: "C",
    logoskill: "/Images/tailwindcss.svg",
    path: "https://tailwindcss.com/",
  },
  {
    id: 15,
    skill: "git",
    logoskill: "/Images/Git.svg",
    path: "https://git.com/",
  },
  {
    id: 8,
    skill: "C",
    logoskill: "/Images/github-.png",
    path: "https://github.com/",
  },
];
export default Skilled;
